

import React from 'react';
import Slider from 'react-slick';
import './Carousel.css';


const Carousel = () => {
  const images = [
      { title: 'Impression', description: 'The number of people who have seen your campaign', src: '/images/image8.jpg' },
      { title: 'Engagement', description: 'The level of interaction your campaign has recieved,such as clicks,likes,shares and comments.', src: '/images/image9.jpg' },
      { title: 'Social Media Followers', description: 'The increase in social media followers your campaign has generated.', src: '/images/image10.jpg' },
      { title: 'Leads and Sales', description: 'The number of leads and sales your company has generated', src: '/images/image11.png' },
      { title: 'Brand Awareness', description: 'The imapact your campaign has had on increasing brand recognition and awareness ', src: '/images/image12.webp' },
      { title: 'Analytics +leaderboard', description: 'Get the detailed analytics of campaign performance with leaderboard based on customer engagement', src: '/images/image13.png' }
    ];
    
  

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: false,
    autoplaySpeed: 6000
  };

  const handleGoBack = () => {
    slider.slickGoTo(0);
  };

  let slider;

  return (
    <div className="carousel-container" style={{ backgroundColor: 'lightpurple' }}>
      <h2 className="page-title">METRIC MEASURES</h2>
      <Slider ref={c => (slider = c)} {...settings}>
        {images.map((image, index) => (
          <div key={index} className="carousel-card">
            <img src={image.src} alt={image.title} />
            <div className="card-content">
              <h3 className="card-title">{image.title}</h3>
              <p className="card-description">{image.description}</p>
            </div>
          </div>
        ))}
      </Slider>
      {slider && slider.state.currentSlide === images.length - 1 ? (
        <button className="go-back-btn" onClick={handleGoBack}>Go Back</button>
      ) : null}
    </div>
  );
};

export default Carousel;