import React from 'react';
import './Footer.css'; // External CSS file for styling

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-section">
          <h3>Contact</h3>
          <p>&#9993; Email: contact@vistaardigital.com</p>
          <p>&#127760; Instagram: @vistaardigital</p>
        </div>
        <div className="footer-section1">
          <h3>Address</h3>
          <p>&#127968; Paras Business Center, Unit 602,</p>
          <p>Kasturba Cross Rd, Mumbai, India</p>
        </div>
      </div>
      <div className="copyright">
        <p>&copy; 2024 Iqra Khan Vistaar Digital. All rights reserved.</p>
      </div>
    </footer>
  );
};

export default Footer;
