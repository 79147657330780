import React from 'react';
import './App.css';
import './components/header/Header';
import Header from './components/header/Header';
import Slider from './slider/Slider';
import FeatureSection from './feature/FeatureSection';
import Carousel from './carousel/Carousel';
import ContactForm from './contactform/ContactForm';
import Footer from './footer/Footer';






function App() {
  return (
<div className="App">
   <Header />
   <toggleMenu />
  <Slider />
  <FeatureSection/>
<Carousel/>
<ContactForm/>
<Footer/>


  
  
</div>
  );
}

export default App;
