import React, { useState } from "react";
import "./Header.css";

const Header = () => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <header className="header">
      <div className="left">
        <div className="title">
          <h1>
            Build with ACTIVATR: <br /> Unleash your Brand's Power!
          </h1>
          <p className="description">
            "Empowering Connections, Enriching Lives: we're dedicated to
            forging meaningful relationships and transforming experiences.
            With innovative solutions and unwavering commitment, we pave the
            way for boundless possibilities, guiding you towards a future of
            success and growth."
          </p>
        </div>
      </div>
      <div className="right">
        <img src="images/image15.png" alt="Your Alt Text Here" />
      </div>
      <div className={`side-menu ${menuOpen ? "open" : ""}`}>
        {/* Close button for sidebar */}
        <div className="close-button" onClick={closeMenu}>
          &times;
        </div>
        {/* Side menu items */}
        <ul className="menu-list">
          <li>Home</li>
          <li>Campaign Ideas</li>
          <li>Metric Measures</li>
          <li>Create Campaign</li>
        </ul>
      </div>
      <div className="menu-icon" onClick={toggleMenu}>
        ☰
      </div>
    </header>
  );
};

export default Header;
