import React, { useState } from 'react';
import './ContactForm.css'; // External CSS file for styling

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: '',
    contact: '',
    purpose: ''
  });
  const [submitted, setSubmitted] = useState(false);

  const handleChange = e => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = e => {
    e.preventDefault();
    // Check if all required fields are filled
    if (formData.name && formData.contact && formData.purpose) {
      // You can handle form submission logic here, like sending the data to a server
      setSubmitted(true);
    }
  };

  const handleDoneClick = () => {
    // Handle redirection logic to the home page
    console.log('Redirecting to home page...');
  };

  return (
    <div className="contact-form-container">
      <div className="form-container">
        <h2 className="title">CONTACT DETAILS</h2>
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label>Name:</label>
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleChange}
              placeholder="Enter your name"
              required
            />
          </div>
          <div className="form-group">
            <label>Contact:</label>
            <input
              type="text"
              name="contact"
              value={formData.contact}
              onChange={handleChange}
              placeholder="Enter your contact"
              required
            />
          </div>
          <div className="form-group">
            <label>Purpose of Campaign:</label>
            <input
              type="text"
              name="purpose"
              value={formData.purpose}
              onChange={handleChange}
              placeholder="Enter purpose"
              required
            />
          </div>
          <button type="submit" className="send-button">Send</button>
        </form>
        {submitted && (
          <div className="confirmation">
            <p>ACTIVATR TEAM WILL REACH OUT SOON !!</p>
            <button onClick={handleDoneClick}>Done</button>
          </div>
        )}
      </div>
      <div className="image-container">
        {/* Image component or image element */}
        <img src="images/image14.png" alt="Your Image" />
      </div>
    </div>
  );
};

export default ContactForm;
