import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./Slider.css";

const SliderComponent = () => {
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    speed: 6000,
    autoplaySpeed: 3000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024, // Medium screens and above
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 768, // Mobile devices
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1
        }
      }
    ]
  };

  return (
    <div className="mainContainer">
      <Slider {...settings}>
        <div className="container">
          <div className="text">IGNITE BUZZ</div>
        </div>
        <div className="container">
          <div className="text">IGNITE BUZZ</div>
        </div>
        <div className="container">
          <div className="text">DRIVE SALES</div>
        </div>
        <div className="container">
          <div className="text">GRAB ATTENTION</div>
        </div>
        <div className="container">
          <div className="text">DRIVE SALES</div>
        </div>
        <div className="container">
          <div className="text">GRAB ATTENTION</div>
        </div>
      </Slider>
    </div>
  );
};

export default SliderComponent;
